@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  position: relative; /* Add position relative to body */
  background-image: url('./assets/SITE_BG.webp');
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Keeps background in place during scroll */
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  body, html {
    background-size: auto; /* Prevents zoom-in or cropping on mobile */
   background-repeat: repeat;
  }
}

/* This creates a semi-transparent overlay */
body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay with 50% transparency */
  z-index: 1; /* Make sure the overlay is above the background */
  pointer-events: none; /* Allow interaction with the page content */
}

/* Ensure content is above the overlay */
body * {
  position: relative;
  z-index: 2; /* Content is above the overlay */
 font-family: 'Anton', sans-serif;
}

.app {
  text-align: center;
  padding: 20px;
}

header {
  padding: 20px;
  border-bottom: 1px solid #333;
}

.services {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Allows services to wrap */
  padding: 40px 0;
}

.service {
  background: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  width: 30%;
  margin: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .services {
    flex-direction: column;
    align-items: center;
  }

  .service {
    width: 80%;
  }
}

h1 {
  font-size: 2.5rem;
}

p {
  font-size: 1.1rem;
}

h2 {
  font-size: 2rem;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  p {
    font-size: 1rem;
  }

  .app {
    padding: 10px;
  }
}

.image-placeholder {
  width: 100%;
  height: 200px;
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.socials {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
}

.tweets iframe {
  background-color: #1e1e1e !important; /* Force dark background */
  color: #fff !important; /* Force text color */
}

